body {
  font-family: "Segoe UI", Arial, sans-serif;
  background-color: #282c34;
  line-height: 1.4;
  height: 100vh;
  color: #FFFFFF;
}  

.header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
}

.inner {
  width: 100%;
  padding: 8rem 10px 4rem;
}

.form-container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  border-radius: calc(0.5 * 100px);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.38);
  justify-content: space-evenly;
}

.input-select {
  font-size: 1rem;
  font-weight: 400;
  width: 85%;
  padding-right: 5px;
  padding-left: 10px;
  border-radius: calc(0.5 * 100px);
}

.input-text {
  font-size: 1rem;
  font-weight: 400;
  width: 85%;
  padding-right: 5px;
  padding-left: 10px;
  border-radius: calc(0.5 * 100px);
}

.input-text::placeholder {
  color: #000;
}

.input-submit {
  background: transparent;
  color: #5b5b5b;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 600;
  margin-right: 0px;
}

.input-text,
.input-submit {
  height: 45px;
  outline: none;
  border: none;
}

 